import { Link, navigate } from "gatsby"
import React from "react"
import { Breadcrumb, Col, Container, Row } from "react-bootstrap"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./BreadcrumbModule.scss"
import { Helmet } from "react-helmet"

const BreadcrumbModule = props => {
  // const handleBack = () => {
  //   window?.history?.back()
  // }

  const { isMobile } = useDeviceMedia()

  const handleBack = props => {
    if (props.alias === "sales" && props.department === "residential") {
      navigate("/properties/for-sale/in-dubai/")
    } else if (
      props.alias === "lettings" &&
      props.department === "residential"
    ) {
      navigate("/properties/to-rent/in-dubai/")
    } else if (
      props.alias === "sales" &&
      props.department === "new_developments"
    ) {
      navigate("/off-plan-properties/for-sale/in-dubai/")
    } else if (props.alias === "sales" && props.department === "commercial") {
      navigate("/commercial-properties/for-sale/in-dubai/")
    } 
    else if (props.alias === "lettings" && props.department === "commercial") {
      navigate("/commercial-properties/to-rent/in-dubai/")
    }
  }
  // console.log(props?.choose_menu)
  // let pageUrl = typeof window !== "undefined" ? window.location.href : ""

  var page = ""
  var subsubpage = ""
  var subsubtitle = ""
  var subpage = ""
  var subtitle = ""
  if (props?.choose_menu?.length > 0) {
    if (props?.choose_menu[0]?.strapi_parent?.strapi_parent?.slug) {
      subsubpage =
        "/" + props?.choose_menu[0]?.strapi_parent?.strapi_parent?.slug + "/"
      subsubtitle = props?.choose_menu[0]?.strapi_parent?.strapi_parent?.title
      subpage =
        "/" +
        props?.choose_menu[0]?.strapi_parent?.strapi_parent?.slug +
        "/" +
        props?.choose_menu[0]?.strapi_parent?.slug +
        "/"
      subtitle = props?.choose_menu[0]?.strapi_parent?.title
    } else if (props?.choose_menu[0]?.strapi_parent?.slug) {
      subpage = "/" + props?.choose_menu[0]?.strapi_parent?.slug + "/"
      subtitle = props?.choose_menu[0]?.strapi_parent?.title
    } else {
      page = "/" + props?.choose_menu[0].slug + "/"
    }
  }

  let staticMenu=""
  let staticMenuParent = ""
  let staticMenuParentUrl = ""
  let staticMenuChild =""
  let staticMenuChildUrl = ""

  let dynamicmenu1 = ""
  let dynamicmenu2 = ""
  let dynamicmenu2Url = ""
  let dynamicmenu3 = ""
  let dynamicmenu3Url = ""
  

  // breadcrumb schema
  if(props.choose_menu?.length>0){
    staticMenu = props.choose_menu?.length>0 ? props.choose_menu[0]:""
    staticMenuParent = staticMenu?.strapi_parent?.title ? staticMenu.strapi_parent.title : "" 
    staticMenuParentUrl = staticMenu?.strapi_parent?.slug ? `${process.env.GATSBY_SITE_URL}/${staticMenu.strapi_parent.slug}`: ""
    staticMenuChild = staticMenu?.title ? staticMenu.title:"";
    staticMenuChildUrl = staticMenu?.slug ? `${process.env.GATSBY_SITE_URL}/${staticMenu.slug}`:"";


    dynamicmenu1 ="Home";
    dynamicmenu2 = 
    staticMenuParent ? staticMenuParent: staticMenuChild ? staticMenuChild : "";

    dynamicmenu2Url = 
    staticMenuParentUrl? staticMenuParentUrl+"/" : staticMenuChildUrl ? staticMenuChildUrl+"/" : "";

    dynamicmenu3 =staticMenuParent ? staticMenuChild : ""

    dynamicmenu3Url = staticMenuParentUrl ? `${staticMenuParentUrl}/${ staticMenu?.slug}/` : ""
  }
  

  let menuBreadcrumbJson = {
    "@context": "https://schema.org",	
  "@type": "BreadcrumbList",	
  "@id": "BreadcrumbList",
  itemListElement: [	
    {	
      type: "ListItem",	
      position: 1,	
      name: dynamicmenu1,	
      item: "https://www.hausandhaus.com/"	
    },	
  ]	
  }

  if(dynamicmenu2){
    menuBreadcrumbJson.itemListElement.push(
      {
        type: "ListItem",	
        position: 2,	
        name: dynamicmenu2,	
        item: dynamicmenu2Url	
      }
    )
  }

  if(dynamicmenu3){
    menuBreadcrumbJson.itemListElement.push(
      {
        type: "ListItem",	
        position: 3,	
        name: dynamicmenu3,	
        item: dynamicmenu3Url	
      }
    )
  }

  //----------------------------

  return (
    <div className="breadcrumb-module-wrapper">
      {props?.choose_menu?.length > 0 &&
       <Helmet>
           <script type="application/ld+json">
            {JSON.stringify(menuBreadcrumbJson, null, 2)}
          </script> 
       </Helmet>
      }
      <Container
        className={`${
          props.type === "property-details-page" ||
          props.type === "details-page" ||
          props.type === "share-button"
            ? "details-container"
            : ""
        }`}
      >
        <Row>
          <Col>
            <Breadcrumb>
              {(props?.type === "property-details-page" ||
                props?.type === "commercial-details-page") && (
                <a
                  href="javascript:void(0);" role="button"
                  className="breadcrumb-item back-to-list"
                  onClick={() => handleBack(props)}
                  aria-label="Back to property listing"
                >
                  <i className="icon grey-arrow-icon" />
                  <span>Back to Listings</span>
                  <div className="vert-line"></div>
                </a>
              )}
              {props.type === "property-details-page" && (
                <Link to="/" className="breadcrumb-item home d-none d-md-block" aria-label="Home page">
                  <span>Home</span>
                </Link>
              )}
              {props.type !== "property-details-page" && (
                <Link to="/" className="breadcrumb-item  home">
                  <span className="landing">Home</span>
                </Link>
              )}
              {subsubpage && (
                <Link to={`${subsubpage}`} className="breadcrumb-item">
                  <span>{subsubtitle}</span>
                </Link>
              )}
              {subpage && (
                <Link to={`${subpage}`} className="breadcrumb-item">
                  <span>{subtitle}</span>
                </Link>
              )}
              {props?.mainparent && (
                <Link to={`/${props.mainparent}/`} className="breadcrumb-item">
                  <span>{props.mainparentname}</span>
                </Link>
              )}
              {props?.parent && (
                <Link to={`/${props.parent}/`} className="breadcrumb-item">
                  <span>{props.parentname}</span>
                </Link>
              )}
              {props?.subparent && (
                <Link to={`/${props.subparent}/`} className="breadcrumb-item">
                  {props.subparentname}
                </Link>
              )}

              {/* {props.type === "property-details-page" &&
              !isMobile&&(
              props?.department === "commercial" ? (
                <Link to={`/commercial/`} className="breadcrumb-item">
                  <span>Commercial</span>
                </Link>
              ) : props.type === "property-details-page" &&
                props?.department === "new_developments" ? (
                <Link to={`/new-homes/`} className="breadcrumb-item">
                  <span>New Homes</span>
                </Link>
              ) : props.type === "property-details-page" &&
                props?.alias === "sales" ? (
                <Link to={`/sales/`} className="breadcrumb-item">
                  <span>Sales</span>
                </Link>
              ) : props.type === "property-details-page" &&
                props?.alias === "lettings" ? (
                <Link to={`/lettings/`} className="breadcrumb-item">
                  <span>Lettings</span>
                </Link>
              ) : null
            )} */}

              {props.type === "property-details-page" && (
                <p
                  // to={`/properties/${props?.alias === "sales" ? 'for-sale' : 'to-rent'}`}
                  onClick={() => handleBack(props)}
                  onKeyPress={() => handleBack()}
                  // href="javascript:void(0)"
                  variant="none"
                  className="breadcrumb-item d-none d-md-block"
                  aria-label="Property result page"
                >
                  {props?.alias === "sales" &&
                  props.department === "new_developments" ? (
                    <span>Off Plan for Sale</span>
                  ) : props?.alias === "sales" &&
                    props.department === "residential" ? (
                    <span>Property for sale in Dubai</span>
                  ) : props?.alias === "sales" &&
                    props.department === "commercial" ? (
                    <span>Commercial property for sale in Dubai</span>
                  ) : props?.alias === "lettings" ? (
                    <span>Property to rent</span>
                  ) : (
                    ""
                  )}
                </p>
              )}
              {props.type === "property-details-page" && (
                <Breadcrumb.Item className="d-none d-md-block" aria-label="Property details page" active>{props.pagename}</Breadcrumb.Item>
              )}
              {props.type !== "property-details-page" && (
                <Breadcrumb.Item aria-label="Property details page" active>{props.pagename}</Breadcrumb.Item>
              )}
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BreadcrumbModule
